@font-face { 
    font-family: "Geometria";
    src: url(../fonts/Geometria/Geometria.eot?) format("eot"),
         url(../fonts/Geometria/Geometria.woff) format("woff"),
         url(../fonts/Geometria/Geometria.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face { 
    font-family: "Geometria";
    src: url(../fonts/Geometria-ExtraBold/Geometria-ExtraBold.eot?) format("eot"),
         url(../fonts/Geometria-ExtraBold/Geometria-ExtraBold.woff) format("woff"),
         url(../fonts/Geometria-ExtraBold/Geometria-ExtraBold.ttf) format("truetype");
    font-weight: 800;
    font-style: normal;
}
@font-face { 
    font-family: "Geometria";
    src: url(../fonts/Geometria-Medium/Geometria-Medium.eot?) format("eot"), 
         url(../fonts/Geometria-Medium/Geometria-Medium.woff) format("woff"),
         url(../fonts/Geometria-Medium/Geometria-Medium.ttf) format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face { 
    font-family: "Geometria";
    src: url(../fonts/Geometria-Bold/Geometria-Bold.eot?) format("eot"), 
         url(../fonts/Geometria-Bold/Geometria-Bold.woff) format("woff"),
         url(../fonts/Geometria-Bold/Geometria-Bold.ttf) format("truetype");
    font-weight: 700;
    font-style: normal;
}