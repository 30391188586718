.lead-block, .reserve, .you-result, .sale, .contact-us {
	background-size: cover;
}

/* lead-block */
	/* main-heading */
		.header	{
			margin-bottom: 133px;
			position: relative;
			z-index: 10;
		}
		.logo {
			padding-top: 20px;
		}
		.logo-text {
			color: #85929a;
			font-size: 16px;
			font-weight: 700;
			text-transform: uppercase;
			margin-left: 7px;
		}
		.page-heading {
			font-weight: 700;
			padding-top: 13px;
			line-height: 24px;
		}
		.phone-number {
			font-size: 20px;
			position: relative;

		}
		.lead-block {
			background:url(../img/header_bg.jpg) no-repeat;
			background-position: top;
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 335px;
			background-size: cover;
			&__container {
				padding-left: 0;
				padding-right: 0;
			}
		}
		.lead-content {
			position: relative;
			z-index: 1;
		}
		.callback-header {
			padding-top: 10px;
		}
		.phone-number {
			&:before {
				content: '\f095';
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
				font-size: 16px;
				border: 1px solid #000000;
				border-radius: 50%;
				padding: 1px 5px;
				position: absolute;
				left: -40px;
				top:50%;
				transform: translate(0,-50%) rotate(90deg);
			}
		}
		.phone-description {
			font-weight: 800;
			color: #009ee2;
			position: relative;
			line-height: 24px;
		}
		.phone-description {
			&:after {
				content: '\f061';
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
				display: inline-block;
			}
			&:hover {
				color: #1fbcff;
			}
		}
	/* main-heading */
	/* lead-content */
		.main-heading {
			font-size: 48px;
			font-weight: 800;
			padding-bottom: 42px;


			position: relative;
			margin-bottom: 70px;
			max-width: 900px;
			&:after {
				content: '';
				width: 165px;
				height: 2px;
				background: #009ee2;
				position: absolute;
				bottom: 0;
			}
			&__primary {
				margin-bottom: 25px;
				font-weight: 800;
				.accent-underline {
					&:before {
						height: 20px;
					}
				}
			}
			&__secondary {
				font-family: "Geometria";
				font-size: 24px;
				line-height: 28px;
				padding-right: 60px;
			}
		}


		.accent-color {
			color: #009ee2;
		}
		.accent-underline {
			position: relative;
			z-index: 2;
			display: inline-block;
			&:before {
				content: '';
				display: block;
				position: absolute;
				width: 102%;
				bottom: 0;
				left: -2.5%;
				height: 15px;
				background:linear-gradient(to right, #01dcfc,#7aeeff);
				border-radius: 15px;
				z-index: -1;
			}
		}

	/* lead-content */
	/* lead-form*/
		.lead-form {
			&__legend {
				font-weight: 800;
				font-size: 20px;
				line-height: 24px;
				margin-bottom: 25px;
				box-shadow: inset 0px 0px 50px 50px rgba(256,256,256,0.7),
							0px 0px 50px 50px rgba(256,256,256,0.7);
				position: relative;
				padding-right: 85px;
				&:before {
					content: '';
					width: 262px;
					height: 262px;
					position: absolute;
					background: url(../img/heading_bg.png);
					right: -20px;
					top: -60px;
					z-index: 0;
				}
			}
			&__input-block {
				position: relative;
				margin-left: 0px;
				&:after {
					content: "";
					position: absolute;
					width: 90px;
					height: 30px;
					left: 315px;
					transform: translate(-50%, 75px);
					background-repeat: no-repeat;
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='89px' height='29px'%3E%3Cpath fill-rule='evenodd' fill='rgb(0, 158, 226)' d='M87.113,0.401 C79.261,2.778 71.557,5.749 63.409,7.384 C62.076,7.680 62.076,9.909 63.409,10.207 C66.964,10.801 70.372,11.840 73.779,12.435 C68.891,16.297 64.594,19.715 58.520,22.092 C51.409,24.765 43.853,25.806 36.297,25.509 C20.297,25.063 6.223,16.595 1.926,0.699 C1.630,-0.342 -0.148,-0.193 0.000,0.995 C1.778,17.486 16.297,26.846 31.705,28.629 C44.890,30.114 65.631,27.440 75.409,16.149 C75.705,19.269 76.742,22.240 78.520,24.914 C79.261,25.954 80.446,25.954 81.187,24.914 C85.780,18.229 86.817,9.909 88.891,2.332 C89.335,1.144 88.298,0.104 87.113,0.401 ZM78.372,13.326 C78.372,13.029 78.224,12.732 78.076,12.435 C78.817,10.949 77.039,9.909 75.854,10.801 L75.705,10.949 C75.705,10.949 75.705,10.949 75.557,10.949 C73.483,10.355 71.409,9.612 69.335,9.018 C74.668,7.680 80.002,5.749 85.335,4.115 C83.854,9.909 82.817,15.852 79.854,21.051 C78.965,18.674 78.520,16.149 78.372,13.326 Z'/%3E%3C/svg%3E");
				}
			}
			.input-item {
				margin-right: 25px;
				width: 303px;
			}
		}
		.input, .btn {
			width: 100%;
			box-sizing: border-box;
			border-radius: 100px;
			box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.28);
		}

		.input-item {
			display: inline-block;
			position: relative;
		}
		.input {
			background: #ffffff;
			height: 53px;
			padding: 0 25px 0;
		}
		.btn {
			background: #008aff;
			color: #ffffff;
			font-size:18px;
			font-weight: 800;
			width: 282px;
		}
		.fa-arrow-right {
			font-size: 12px;
			margin-left: 5px;
			display: inline-block;
			color: inherit;
		}
	/* lead-form*/
/* lead-block */
/* why-us */
	/* why-us__heading */
		.decor-circle-right, .decor-circle-left, 
		.decor-lines-right, .decor-lines-left {
			position:relative;
		}
		.decor-circle-right:before,
		.decor-lines-right:before {
			content: url('../img/decor_circle.png');
			position: absolute;
			right: 0;
			top: -290px;
			z-index: 0;
		}
		.decor-circle-left:before, 
		.decor-lines-left:before {
			content: url('../img/decor_circle.png');
			position: absolute;
			left: 0;
			top: -350px;
			transform: rotate(180deg);
			z-index: 0;
		}
		.decor-lines-left:before, 
		.decor-lines-right:before {
			content: url(../img/decor_lines.png);
		}

		.total.decor-lines-left:before {
			content: url(../img/decor_lines-blue.png);
			transform: rotate(0deg);
			top: -700px;
		}

		.reserve.decor-circle-left:before {
			top: -200px;
		}
		.you-result.decor-lines-right:before {
			top: -50px;
		}
		.sale.decor-circle-right:before {
			top:-300px;
		}
		.total.decor-lines-left:before {
			top: -800px;
		}
		.contact-us.decor-circle-left:before {
			top:-250px
		}
		.secondary-heading {
			text-align: center;
			font-weight: 800;
			font-size: 42px;
			position: relative;
			padding-bottom: 73px;
			margin-bottom: 95px;
		}

		.secondary-heading:before {
			content: '';
			width: 158px;
			height: 158px;
			position: absolute;
			background: url(../img/heading_bg.png);
			left: 25px;
			top: -50px;
			z-index: 0;
		}

		.secondary-heading:after {
			content: '';
			width: 40px;
			height: 4px;
			background: #009ee2;
			position: absolute;
			bottom: -2px;
			left: 50%;
			transform: translate(-50%,0);
		}
	/* why-us__heading */
	/* why-us__arguments */
		.why-us.container-fluid {
			padding-top: 60px;
		}
		.why-us {
			&__icon {
				width: 75px;
				height: 60px;
				flex-shrink: 0;
				text-align: center;
			}
			&__arguments-item {
				position: relative;
				margin-bottom: 65px;
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				-ms-align-items: flex-start;
				align-items: flex-start;
				a {
					color: #009ee2;
					font-weight: 700;
				}
			}
			.secondary-heading:before {
				left: 120px;
			}
			&__description {
				margin-left: 20px;
			}
			&__arguments-heading {
				font-weight: 800;
				font-size: 24px;
				margin-bottom: 15px;
				line-height: 100%;
			}
			&__arguments-text {
				margin-bottom: 0;
			}
			&__partners {
				position: relative;
				&__info {
					width: 100%;
					background: #eff5f8;
					border-radius: 15px;
					text-align: center;
					padding: 38px 78px 46px;
					font-weight: 500;
					font-size: 24px;
					margin-bottom: 30px;
					position: relative;
					z-index: 2;
					line-height: 30px;
					margin-bottom: 0px;
					.accent-color {
						font-weight: 800;
					}
				}
				&:before {
					content: '';
					width: 158px;
					height: 158px;
					position: absolute;
					background: url(../img/heading_bg.png);
					left: 160px;
					top: -30px;
					z-index: -20;
				}
				&:after {
					content: '';
					width: 83px;
					height: 83px;
					position: absolute;
					background: url(../img/heading_bg.png);
					right: 170px;
					bottom: 10px;
					z-index: -20;
				}
			}
			&__partners-arrow {
				content: url(../img/map_arrow.png);
				width: 97px;
				height: 97px;
				margin: 0 auto;
				position: relative;
				animation: floating 3s infinite ease-in-out;
				z-index: 10;
				margin-top: 20px;
				margin-bottom: -20px;
			}
			&__map-holder {
				margin-top: -0px;
				margin-bottom: 100px;
				position: relative;
				img {
					max-width: 100%;
				}
				ul {
					height: 0;
				}
				li {
					position: absolute;
					list-style: none;
					font-weight: 700;
					font-size: 14px;
					color: #001d29;
					text-align: center;
					line-height: 1.2;
					i {
						color: #009ee2;
					}
				}
			}
		}


		@keyframes floating {
			0% {
				top: -15px;
			}
			50% {
				top: 15px;
			}
			100% {
				top: -15px;
			}
		}

	/* why-us__partners */
/* why-us */
/* reserve */
	.reserve {
		background:url(../img/reserv_bg.jpg) no-repeat;
		background-position: top;
		padding-top: 205px;
		padding-bottom: 120px;
		margin-left: 0px;
		background-size: cover;
	}
	.reserve-heading .secondary-heading{
		text-align: left;
		padding-bottom: 60px;
		margin-bottom: 70px;
	}
	.reserve-heading .secondary-heading:after{
		left:20px;
	}
	.reserve-heading .secondary-heading:before {
		left: -75px;
	}
	
	.reserve-form {
		&__input-block {
			width: 303px;
			.input-item {
				margin-bottom: 20px;
				position: relative;
				width: 100%;
				&:after {
					content: "";
					position: absolute;
					top: 20px;
					right: -60px;
					width: 40px;
					height: 90px;
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='40px' height='88px'%3E%3Cpath fill-rule='evenodd' fill='rgb(255, 255, 255)' d='M0.475,85.353 C4.361,78.127 8.800,71.166 12.017,63.503 C12.573,62.255 14.757,62.697 14.784,64.063 C14.662,67.665 15.005,71.211 14.912,74.669 C19.668,70.643 23.869,67.110 27.403,61.627 C31.433,55.186 33.950,47.987 35.157,40.522 C37.892,24.751 32.382,9.277 17.652,1.915 C16.691,1.418 17.189,-0.295 18.325,0.086 C34.136,5.098 40.432,21.184 39.125,36.639 C37.968,49.857 31.235,69.657 18.230,77.002 C21.229,77.911 23.936,79.517 26.205,81.790 C27.077,82.722 26.842,83.883 25.676,84.403 C18.213,87.580 9.853,86.947 2.015,87.478 C0.762,87.678 -0.051,86.456 0.475,85.353 ZM14.876,79.347 C14.584,79.288 14.323,79.084 14.061,78.880 C12.458,79.311 11.791,77.363 12.900,76.378 L13.075,76.262 C13.075,76.262 13.075,76.262 13.104,76.117 C12.933,73.966 12.616,71.786 12.445,69.635 C10.076,74.598 7.126,79.443 4.467,84.346 C10.440,84.043 16.470,84.204 22.154,82.331 C20.001,80.988 17.613,80.052 14.876,79.347 Z'/%3E%3C/svg%3E");
					background-repeat: no-repeat;
				}
			}
		}
		&__btn {
			display: block;
			width: 300px;
			padding: 15px 0;
			letter-spacing: 1px;
		}
	}
	
/* reserve */
/* you-result */
	.you-result {
		background:url(../img/result_bg.jpg) no-repeat;
		background-position: center bottom;
		background-size: cover;
		padding-top: 115px;
		padding-bottom: 200px;
		color:#ffffff;
		&__heading {
			padding: 0;
		}
		.secondary-heading{
			margin-bottom: 68px;
			text-align: center;
			span {
				position: relative;
			}
			&:after {
				background: #ffffff;
			}
			.accent-underline:before {
				background: #0078ab;
			}
		}
		&__item {
			text-align: center;
			font-size: 20px;
			padding: 0 35px;
			background: url(../img/ellipse.svg) no-repeat center 10px;
			&:first-of-type {
				.you-result__item-text {
					padding: 0 20px;
				}
			}
		}
		&__item-heading {
			font-weight: 800;
			font-size: 36px;
			margin-bottom: 12px;
		}
		&__item-text {
			line-height: 1.15;
		}
	}


/* you-result */
/* reviews */
	.slider {
		position: relative;
		margin-bottom: 130px;
		-webkit-flex-direction: row;
		-moz-flex-direction: row;
		-ms-flex-direction: row;
		-o-flex-direction: row;
		flex-direction: row;
	}
	.reviews {
		padding-top: 115px;
		&__heading {
			.secondary-heading {
				padding-bottom: 65px;
				margin-bottom: 72px;
				&:before {
					left: -75px;
					top: -40px;
				}
			}
		}
	}
	.review-author {
		&__name {
			font-weight: 800;
			margin:10px 0 0;
		}
		&__company {
			margin-bottom: 50px;
		}
	}
	.review-text {
		text-align: center;
		position: relative;
		display: -webkit-box;
		position: relative;
		font-size: 16px;
		line-height: 1.75;
		margin-bottom: 30px;
	}

	.review-text:before,.review-text:after {
		content: '\f10e';
		font-family: "Font Awesome 5 Free";
		font-weight: 900;
		position: absolute;
		color: #009ee2;
		font-size: 16px;
	}

	.review-text:before {
		top: -30px;
		left: 0;
	} 
	
	.review-text:after {
		bottom:-30px;
		right: 0;
		transform: rotate(180deg);
	}

	.slider-wrap {
		position: relative;
	}
	.slide {
		display: none;
	}
	.slide-active {
		display: block;
	}
	.slider-navigation {
		list-style: none;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		position: absolute;
		bottom: -57px;
		overflow: visible;
	}
	.slider-navigation li {
		width: 8px;
		height: 8px;
		margin: 0 4px;
		background: #009ee2;
		opacity: 0.3;
		border-radius: 50%;
		cursor: pointer;
		overflow: visible;

		list-style: none;

	}
	.slider-navigation li button {
		color: transparent;
		overflow: visible;

	}
	.slider-navigation li:hover {
		opacity: .7
	}
	.slider-navigation li.slick-active {
		opacity: 1;
	}
	.slider-arrow {
		width: 45px;
		height: 45px;
		background: #e9eff2;
		border-radius: 100%;
		position: absolute;
		cursor: pointer;
		top: calc(50% + 20px);
		transition: 0.3s;
		overflow: visible;
		list-style: none;
	}
	.slider-arrow:before {
		content: "";
		position: absolute;
		left: 20px;
		top: 50%;
		transform: translateY(-50%);
		width: 49px;
		height: 15px;
		background-repeat: no-repeat;
		background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2249px%22%20height%3D%2215px%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20%20fill%3D%22rgb(0%2C%2086%2C%20203)%22%20d%3D%22M48.220%2C8.276%20L2.663%2C8.276%20L7.985%2C13.654%20C8.290%2C13.962%208.290%2C14.461%207.985%2C14.769%20C7.833%2C14.923%207.633%2C15.000%207.434%2C15.000%20C7.234%2C15.000%207.035%2C14.923%206.883%2C14.769%20L0.230%2C8.046%20C0.158%2C7.973%200.100%2C7.886%200.061%2C7.789%20C0.023%2C7.697%200.005%2C7.599%200.003%2C7.501%20C0.003%2C7.496%200.001%2C7.493%200.001%2C7.488%20C0.001%2C7.488%200.001%2C7.488%200.001%2C7.488%20C0.001%2C7.385%200.021%2C7.283%200.061%2C7.186%20C0.100%2C7.090%200.157%2C7.003%200.229%2C6.931%20L6.883%2C0.208%20C7.187%2C-0.100%207.681%2C-0.100%207.985%2C0.208%20C8.290%2C0.515%208.290%2C1.014%207.985%2C1.321%20L2.663%2C6.700%20L48.220%2C6.700%20C48.651%2C6.700%2049.000%2C7.053%2049.000%2C7.488%20C49.000%2C7.923%2048.651%2C8.276%2048.220%2C8.276%20Z%22%2F%3E%3C%2Fsvg%3E");
	}
	.slider-arrow-prev {
		left: 70px;
	}
	.slider-arrow-prev:hover {
		transform: scale(1.4);
		transition: 0.3s;
	}
	.slider-arrow-next {
		transform: rotate(180deg);
		right: 70px;
	}
	.slider-arrow-next:hover {
		transform: rotate(180deg) scale(1.4);
		transition: 0.3s;
	}
/* reviews */
/* sale */
	.sale {
		background: url(../img/sale_bg.png) no-repeat top center;
		padding: 165px 0 140px;
		text-align: center;
		background-size: cover;
	}
	.reviews .slider-arrow {
		z-index: 2
	}
	.sale-number {
		background: #fff;
		border-radius: 50%;
		padding: 30px 15px;
		font-size: 48px;
		font-weight: 800;
		color: #009ee2;
		position: relative;
		margin-bottom: 65px;
		display: inline-block;
	}
	.sale-number:before {
		content: '';
		border: 1px solid #009ee2;
		border-radius:50%;
		width: 170px;
		height: 170px;
		padding: 60px 30px;
		position: absolute;
		top: calc(50% - 85px);
		left: calc(50% - 85px);
		opacity: .36;
	}
	.sale-number:after {
		content: '';
		border: 1px solid #009ee2;
		border-radius:50%;
		width: 200px;
		height: 200px;
		padding: 90px 45px;
		position: absolute;
		top: calc(50% - 100px);
		left: calc(50% - 100px);
		opacity: .18;
	}

	.sale-text {
		text-align: center;
		font-weight: 700;
		font-size: 24px;
		margin-bottom: 50px;
		display: block;
	}
	.sale-text .accent-underline:before {
		top:58%;
	}
	.sale-btn-holder {
		position: relative;
	}
	.sale-btn-holder .btn {
		width: 205px;
		padding: 15px 0;
	}
	.btn-red {
		background: #dd1c1a;
		margin-left: 30px;
	}
	.sale-btn-holder:after {
		content: '';
		display: block;
		position: absolute;
		bottom: -52px;
		left: 46%;
		transform: translate(-50%,0);
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='90px' height='33px'%3E%3Cpath fill-rule='evenodd' fill='rgb(133, 146, 154)' d='M2.360,0.376 C10.033,3.277 17.519,6.761 25.538,8.942 C26.849,9.328 26.698,11.552 25.348,11.758 C21.760,12.111 18.290,12.918 14.851,13.282 C19.468,17.466 23.524,21.165 29.424,23.946 C36.339,27.094 43.807,28.641 51.366,28.855 C67.359,29.490 81.973,21.991 87.332,6.420 C87.698,5.403 89.462,5.670 89.234,6.846 C86.347,23.180 71.230,31.538 55.737,32.278 C42.481,32.871 21.968,28.802 12.974,16.878 C12.468,19.970 11.233,22.865 9.279,25.413 C8.470,26.400 7.287,26.320 6.618,25.233 C2.487,18.253 2.013,9.882 0.455,2.183 C0.092,0.967 1.197,-0.000 2.360,0.376 ZM10.208,13.861 C10.228,13.565 10.396,13.278 10.564,12.992 C9.925,11.460 11.769,10.542 12.892,11.511 L13.029,11.670 C13.029,11.670 13.029,11.670 13.177,11.680 C15.286,11.227 17.406,10.626 19.516,10.173 C14.284,8.479 9.094,6.192 3.883,4.201 C4.970,10.082 5.603,16.081 8.209,21.470 C9.256,19.158 9.870,16.668 10.208,13.861 Z'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		width: 90px;
		height: 33px;
	}
	.contact-form {
		&__input {
			width: 303px;
		}
		&__btn-wrap {
			width: 100%;
			text-align: center;
		}
		&__input-block {
			.input-item {
				&:first-of-type {
					margin-right: 25px;
				}
			}
		}
	}
/* sale */
/* total */
	.total {
		padding-top: 125px;
	}
	.total-heading .secondary-heading {
		padding-bottom: 88px;
		margin-bottom: 75px;
	}
	.total-heading .secondary-heading:before {
		left: 35px;
	}
	.total-heading .secondary-heading:after {
		bottom: 5px;
	}

	.total-item {
		padding: 0;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		-ms-align-items: flex-start;
		align-items: flex-start;
		&__heading {
			font-size: 24px;
			font-weight: 800;
			line-height: 1;
			margin-bottom: 15px;
		}
	}
	.total-item:nth-child(2) {
		padding-left: 25px;
	}
	.total-item:nth-child(2) img {
		margin-top: 7px;
		margin-right: 18px;

	}
	.total-item:nth-child(3) {
		margin-top: 53px;
		padding-left: 10px;
	}
	.total-item:nth-child(3) img {
		margin-top: -7px;

	}
	.total-item img {
		margin-right: 15px;
	}
	.total-block {
		padding-bottom: 95px;
	}
/* total */
/* contact-us */
	.contact-us {
		background: url(../img/contact_bg.png) no-repeat top center;
		padding-top: 165px;
		padding-bottom: 100px;
	}
	.contact-us-heading .secondary-heading {
		padding-bottom: 45px;
		margin-bottom: 60px;
		text-align: center;
		/*margin-right: -45px;*/
	}
	.contact-us-heading .secondary-heading:after{
		left: 50%;
	}
	.contact-us-heading .secondary-heading:before {
		width: 132px;
		height: 132px;
		left: 0;
		top:-30px;
	}
	.secondary-heading {
		&__secondary {
			font-weight: 500;
			font-size: 20px;
			line-height: 28px;
			display: block;
			margin-top: 20px;
			padding: 0px 35px;
		}
	}

	.contact-form {
		.input-item {
			margin-bottom: 20px;
		}
		&__input-block {
			flex-wrap: wrap;
			margin-left: 0;
			margin-right: 0;
			.input, .contact-form__btn {
				box-shadow: 0px 0px 36px 0px rgba(0, 83, 118, 0.18);
			}
		}
		&__input {
			margin-right: 0;
		}
	}

	.input-item:first-of-type .contact-form__input {
		margin-left: 0px;
	}
	.contact-form {
		&__text {
			height: 126px;
			width: 635px;
			border-radius: 30px; 
			margin: 0 auto;
			padding-top: 15px;
			padding-bottom: 15px;
		}
		&__btn {
			padding: 15px 0;
			position: relative;
			margin: 0 auto;
			width: 303px;
			&:after {
				content: '';
				position: absolute;
				right: -100px;
				top: 0px;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='80px' height='64px'%3E%3Cpath fill-rule='evenodd' fill='rgb(133, 146, 154)' d='M1.988,45.217 C9.958,43.277 18.104,41.923 25.953,39.193 C27.252,38.771 28.384,40.690 27.386,41.624 C24.626,43.942 22.219,46.568 19.585,48.811 C25.759,49.655 31.195,50.416 37.635,49.379 C45.118,48.070 52.155,45.128 58.513,41.035 C72.069,32.524 79.892,18.081 75.519,2.205 C75.246,1.158 76.852,0.383 77.329,1.482 C84.173,16.591 76.420,32.028 64.054,41.390 C53.451,49.367 34.226,57.598 20.068,52.838 C21.397,55.676 22.013,58.762 21.840,61.969 C21.730,63.240 20.709,63.842 19.543,63.323 C12.191,59.897 7.072,53.257 1.437,47.784 C0.450,46.986 0.816,45.564 1.988,45.217 ZM16.082,51.912 C15.931,51.655 15.908,51.324 15.884,50.993 C14.492,50.089 15.495,48.291 16.968,48.457 L17.172,48.509 C17.172,48.509 17.172,48.509 17.299,48.434 C18.784,46.869 20.193,45.176 21.678,43.610 C16.405,45.167 10.830,46.212 5.405,47.513 C9.624,51.752 13.536,56.344 18.729,59.319 C18.288,56.819 17.388,54.418 16.082,51.912 Z'/%3E%3C/svg%3E");
				background-repeat: no-repeat;
				height: 64px;
				width: 80px;
			}
		}
	}
	.input-label-text {
		top: 35px;
	}
/* contact-us */
/* footer */
	.footer {
		padding-top: 5px;
		margin: 0 -15px;
	}
	.footer-block {
		border-bottom: 1px solid #cdcdcd;
		padding-bottom: 15px;
		margin-bottom: 15px;
	}
	.policy {
		color: #008aff;
		padding-bottom: 15px;
		text-align: center;
	}
	.policy a:hover {
		text-decoration: underline;
	}
/* footer */
/* modal windows */
	.modal {
		padding-top: 15px
	}
	.modal-container {
		background:#fff;
		border-radius: 15px;
		border: 10px solid #dfe9f1;
		padding: 30px 70px;
		max-width: 580px;
		margin: 35px auto;
		position: relative;
	}
	.statistic-block {
		max-width: 750px;
		padding-top: 30px;
		padding-bottom: 50px;
	}
	.third-heading {
		font-size: 24px;
		text-align: center;
		padding: 10px 5px;
		line-height: 30px;	
		font-weight: 800;
		position: relative;

	}
	.third-heading-secondary {
		font-size: 18px;
		line-height: 0px;
		font-family: "Geometria";

	}
	.third-heading:after {
		content: '';
		width: 40px;
		height: 4px;
		background: #009ee2;
		position: absolute;
		bottom: -2px;
		left: 50%;
		transform: translate(-50%,0);
	}

	.bonuses-block .third-heading:after {
		display: none;
	}
	.close-modal {
		display:block;
		text-align: right;
		vertical-align: bottom;
		line-height: 1;
		color: #008aff;
		padding-right: 0px;
		margin-bottom: 10px;
		cursor: pointer;
		position: absolute;
		right: 40px;
		top: 15px;
		font-size: 16px;
		z-index: 10;
	}
	.cross {
		font-size: 40px;
		line-height: 0.5;
		position: absolute;
		right:-30px;
		bottom: 0;
	}
	.table {
		margin: 5px 0 40px;
	}
	.table, .table li {
		list-style: none;
		width: 100%;
	}
	.table li {
		display: flex;
		justify-content: space-between;
		text-align: center;
		position: relative;
	}
	.table li:nth-of-type(odd) {
		background:#f6f6f6;
	}
	.table li:nth-of-type(even) {
		background:#e9e9e9;
	}
	.table li:first-of-type {
		background:#009ee2;
		color: #fff;
	}
	.column {
		width: 50%;
		text-align: center;
		padding: 10px 0 10px;

	}
	.column:first-of-type {
		font-weight: 500;
		border-right: 1px solid #a2c7d7;
		font-size: 20px;
	}
	.column:last-of-type {
		font-size: 18px;
	}

	.bonus-form__input-block, .statistic-form__input-block {
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
		min-width: 303px;
	}
	.bonus-form .input-item {
		width: 100%;
		margin-bottom: 15px;
	}
	.bonus-form .btn {
		padding: 15px 0;
		width: 303px;
	}
	.mfp-bg {
		background:rgba(0,0,0,0.55);
	}
	.table li input {
		display: none;
	}
	.table li label:before {
		content: '';
		position: absolute;
		width: 26px;
		height: 26px;
		background:#009ee2;
		left: 25px;

	}
	.table li label:after {
		content: '\f00c';
		position: absolute;
		width: 26px;
		height: 26px;
		left: 25px;
		font-weight: 900;
		font-family: "Font Awesome 5 free";
		color: #44bef2;
	}
	.table li input:checked + label:after {
		color: #fff;
	}
	.statistic-form {
		&__input-block {
			margin-right: 55px;
		}
	}
	.statistic-img {
		display: block;	
		max-width: 100%;
	}
	.bonus-form-statistic {
		margin-top: 50px;
	}
	.bonuses-choose .third-heading,
	.statistic .third-heading {
		padding-bottom: 35px;
		margin-bottom: 35px;
	}
/* modal windows */
.input-icon {
	position: absolute;
	right: 25px;
	top: 50%;
	transform: translateY(-50%);
	color: #a3adb5
}