@media screen and (min-width: 1920px) {
	.lead-block, .reserve, .you-result, .sale, .contact-us {
		background-size: auto;
	}
	.lead-block {
		box-sizing: border-box;
		padding-bottom: 30px;
		min-height: 1038px;
	}
	.reserve {
		box-sizing: border-box;
		padding-bottom: 30px;
		min-height: 687px;
	}
	.you-result {
		box-sizing: border-box;
		padding-bottom: 30px;
		min-height: 657px;
	}
	.sale {
		box-sizing: border-box;
		padding-bottom: 30px;
		min-height: 719px;
	}
	.contact-us {
		box-sizing: border-box;
		padding-bottom: 30px;
		min-height: 780px;
	}
}

@media screen and (max-width: 1200px) {
	.you-result {
		&__item {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

@media screen and (max-width: 1180px) {
	body {
		overflow-x: hidden;
	}
	.decor-circle-right:before, 
	.decor-lines-right:before,
	.decor-circle-left:before, 
	.decor-lines-left:before,
	.decor-circle-right:before, .decor-lines-right:before
	 {
		transform: scale(0.3) translateX(400px);
	}

	.decor-circle-left:before, .decor-lines-left:before,
	.decor-circle-left:before, .decor-lines-left:before,
	.reserve.decor-circle-left:before {
		transform: scale(-0.3) translateX(400px);
	}
	.total.decor-lines-left:before
	 {
		transform: scale(0.3) translateX(-470px);

	}

}
@media screen and (max-width: 1024px) {
	.decor-circle-right:before, 
	.decor-lines-right:before,
	.decor-circle-left:before, 
	.decor-lines-left:before {
		display: none;
	}
	.container {
		max-width: 100%;
		padding: 0 15px;
	}
	.container-fluid {
		padding: 0 15px;
	}
	.header {
		margin-bottom: 97px;
	}
	.phone-number {
		margin-right: -15px;
		font-size: 18px;
		padding-right: 0px
	}
	.phone-number:before {
		font-size: 13px;
		padding: 1px 4px;
		left: -30px;

	}
	.phone-description {
		font-size: 15px;
		margin-right: -15px;
	}
	.logo-text {
		font-size: 13px;
	}
	.logo img {
		width: 55px;
	}
	.page-heading {
		font-size: 16px;
		padding-left:0;
		margin-left: -10px;
		line-height: 20px;
	}
	.main-heading {
		font-size: 36px;
		padding-bottom: 0;
		padding-left: 0;
		margin-bottom: 45px;
		&__secondary {
			font-size: 20px;
			margin-bottom: 0;
			line-height: 25px;
			padding-right: 150px
		}
		&:after {
			display: none;
		}
	}
	.lead-form__legend {
		padding-right: 200px;
		margin-bottom: 25px;
		padding-left: 0;
	}
	.lead-form .col-12 {
		padding-left: 0;
	} 
	.lead-block {
		background-position:center 100%;
		padding-bottom: 235px;
	}
	.lead-form__legend:before{
		right: 0;
		left: -150px;
		top:  -150px;
	}
	.right-decor-circle:before {
		display: none;
	}
	.secondary-heading {
		font-size: 36px;
		padding-bottom: 25px;
	}
	.secondary-heading:before {
		width: 112px;
		height: 112px;
		left:15%;
		top:-20px;
	}
	.secondary-heading {
		margin-bottom: 64px;
	}
	.why-us {
		&__heading {
			padding-left: 0;
		}
		&__arguments-heading {
			font-size: 20px;
		}
		&__arguments-item {
			flex-direction: column;
			padding-right: 30px;
			margin-bottom: 30px;
			&:nth-child(2n) {
				padding-right: 0;
			}
			&:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4) {
				padding-left: 0;
			}
			img {
				max-width: 60px;
			}
		}
		&__description {
			margin-left: 0;
			margin-top: 10px;
		}
	}

	.row {
		margin-left: -15px;
		margin-right: -15px;
	}

	.why-us__arguments-item 
	.why-us__arguments-text {
		font-size: 16px;
		max-width: 340px;
	}

	.why-us__partners__info {
		font-size: 20px;
		padding: 20px 110px 26px 110px;
	}
	
	.why-us__partners:before {
		left: -15px;
	}
	.why-us__partners:after {
		right: -5px;
		bottom: -15px
	}
	.why-us__map-holder {
		margin-top: -15px;
		margin-bottom: 20px;
	}
	.why-us__map-holder img{
		margin-left: -60px;
		margin-top: 20px;
		max-width: 1000px;
	}
	.reserve  {
		padding-top: 65px;
		margin-left: 0;
		padding-bottom: 85px;
	}
	.reserve-heading {
		padding-left: 20px;
		padding-right: 250px;
		margin-bottom: -10px;
	}
	.reserve-heading .secondary-heading:after {
		bottom: 5px;
	}
	.reserve-form__input-block .input-item:after {
		left: 325px;
		top: 35px;
	}

	.reserve-form >div {
		padding-left: 0;
	}
	.reserve-heading .secondary-heading {
		padding-bottom: 55px;
		margin-bottom: 50px;
		margin-left: -15px;
	}

	.you-result__wrap {
		padding-top: 70px;
		padding-bottom: 135px;
	}
	.you-result__wrap .secondary-heading {
		margin-bottom: 55px;
	}
	.you-result__item, .you-result__item:nth-child(1) {
		padding: 0 10px;
		font-size: 16px;
		background-position: top center;
		min-height: 120px;
	}
	.you-result__item-heading {
		font-size: 24px;
	}
	.reviews {
		padding-top: 70px;
	}
	.reviews__heading {
		padding-left: 0;
	}
	.slider-wrap {
		padding-left: 10px;
	}
	.reviews__heading .secondary-heading {
		padding: 0 0 30px;
		margin-bottom: 65px;
	}
	.review-author__company {
		margin-bottom: 20px;
	}
	.slider-navigation {
		bottom: -40px;
	}
	.reviews  {
		padding-bottom: 0px;
		margin-bottom: 0px;
	}
	.review-text  {
		line-height: 24px
	}
	.slick-dotted.slick-slider {
		margin-bottom: 0;
	}
	.reviews__heading .secondary-heading:before {
		left: 0;
	}
	.slider-arrow-prev {
		left: 0;
	}
	.slider-arrow-next {
		right: 0;
	}
	.slider-arrow {
		top: 50%
	}
	.slider {
		margin-bottom: 0;
	}

	.slide {
		margin: 0 auto;
	}
	.sale{
		padding-top: 150px;
		padding-bottom: 130px;
	}
	.sale-number {
		margin-bottom: 63px;
	}
	.sale-text {
		padding: 0px;
	}
	.total {
		padding-top: 55px;
		padding-left: 15px;
		padding-bottom: 50px;
	} 
	.total-heading .secondary-heading {
		margin-bottom: 65px;
		padding-bottom: 25px;
	}
	.total-heading .secondary-heading:before {
		left: 0%;
	}
	.total-item__heading {
		font-size: 20px;
		margin-top: 7px;
		margin-bottom: 15px;
	}
	.total-item:nth-child(2) {
		padding-left: 10px;
	}
	.total-item {
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
		margin-bottom: 10px !important;
	}
	.total-item:nth-child(3) {
		padding-left: 0;
		margin-top: 0;
	}
	.contact-us {
		padding-top: 115px;
		padding-bottom: 80px;
	}
	.contact-us-heading .secondary-heading {
		padding-bottom: 15px;
		margin-right: 0px;
		margin-bottom: 65px;
	}
	.secondary-heading__secondary {
		margin-top: 10px;
		padding: 0 90px;
		max-width: 500px;
		margin:10px auto 0;
	}
	.contact-us-heading .secondary-heading:before {
		left:150px;
	}
	.total-block {
		padding-bottom: 0;
	}
	.footer {
		margin:0;
		padding-top: 5px;
	}
	.footer .logo{
		padding-top: 12px;
		padding-left: 0;
		max-width: 200px;
		white-space: nowrap;
	}
	.policy {
		padding-left: 0;
	}
}
@media screen and (max-width: 767px) {
	.header .page-heading {
		align-self: flex-start;
		text-align: left;
		font-size: 15px;
		line-height: 20px;
		margin-left: 0px;
		width: 245px;
		padding-right: 0;
	}
	.page-heading__fragment {
		display: none;
	}
	.header .logo img {
		width: 44px;
	}
	.header .logo {
		width: auto;
		padding-left: 0;
		padding-right: 10px;
	}
	.header .logo-text {
		display: none;
	}
	.secondary-heading:before {
		left: 0;
	}
	.callback-header {
		padding-left: 0;
		padding-right: 0;
		font-size: 14px;
		display: block;
		margin-left: auto;
		width: auto;
		max-width: 150px;
		flex: auto;
	}
	.phone-number, .phone-description {
	    margin-right: 0px;
	    display: block;
	}
	.phone-description {
		font-size: 14px;
	}
	.contact-form__input-block .input-item {
		width: 390px;
		margin-right: 0 !important;
	}
	.contact-form__input-block .input-item {
		margin-bottom: 15px;
	}
	.contact-form__input-block .input-item .input {
		width: 100%;
	}
	.footer-block {
		flex-wrap: wrap;
	}
	.footer .callback-header {
		margin-right: 0;
	}
	.footer .page-heading {
		padding-left: 30px;
		padding-right: 30px;
	}
	.reserve-form__input-block .input-icon {
		right: 25px
	}
	.bonus-form-statistic {
		flex-direction: column;
	}
	.statistic-form__input-block {
		margin-bottom: 25px;
		margin-right: 0;
	}
	.why-us__map-holder img{
		max-width: 830px;
	}
}
@media screen and (max-width: 575px) {
	.footer-block .mini-logo {
		width: 150px;
	}
	.footer-block .mini-logo img{
		max-width: 150px;
	}
	.footer .page-heading {
		width: 100%;
		display: block;
		order: 2;
		border-top: 1px solid #cdcdcd;
		margin: 10px 0 0;
		padding: 0;
		text-align: center;
		padding-top: 13px;
	}
	.phone-description,
	.phone-number {
		margin-right: 0px;
	}
	.header {
		margin-bottom: 45px;
		padding-top: 5px;
	}
	.callback-header {
		padding-top: 6px;
	}
	.modal-container {
		width: 100%;
	}
	.bonus-form .input, .bonus-form .btn {
		width: 100%;
		max-width: 390px;
		margin-left: auto;
		margin-right: auto;
	}
	.bonus-form {
		text-align: center;
	}
	.table li label:before, .table li label:after {
		left: 15px;
	}
	.callback-header {
		text-align: right;
		line-height: 1.4;
	}
	.phone-number:before {
		display: none;
	}
	.phone-description:after {
		display: none;
	}
	.main-heading {
		text-align: center;
		padding: 0 0 0 0px;
		margin-bottom: 25px;
	}
	.main-heading__secondary{
		padding: 0px 5px;
	} 

	.lead-form .col-12 {
		padding: 0
	}

	.lead-form__legend {
		padding: 0px;
		margin-bottom:35px;
	}
	.footer .callback-header {
		margin-right: 0;
	}
	.lead-form__input-block:after {
		display: none;
	}
	.lead-block .accent-underline:before {
		display: none;
	}
	.lead-block .accent-underline {
		color:#009ee2;
	}
	.lead-form__legend:before {
		top: -400px;
		left:-25px;
	}
	.lead-form {
		&__legend {
			text-align: center;
		}
		&__input-block {
			flex-direction: column;
			align-items: center;
		}
	}
	.btn {
		width: 390px;
		margin-left: auto;
		margin-right: auto;
	} 
	.input {
		margin-right: 0;
	}
	.lead-form .input-item {
		margin-bottom: 25px;
		margin-right: 0;
		width: 390px;
	}	

	.lead-form__btn  {
		padding: 15px 0;
	}
	.lead-block {
		background:url(../img/header_bg_mini.jpg) no-repeat;
		background-position: top;
		background-size: cover;
		padding-bottom: 70px;
	}
	.why-us__heading {
		padding-right: 0;
	}
	.why-us__heading .secondary-heading {
		padding-bottom: 0;
		margin-bottom: 60px;
	}
	.secondary-heading:after {
		display: none;
	}
	.you-result .secondary-heading:after {
		display: block;
	}
	.why-us__arguments-item:nth-of-type(1) .why-us__description {
		padding-right: 0;
	}
	.why-us__arguments-item:nth-of-type(1) img {
		margin-bottom: -5px;
	}
	.why-us__arguments-item:nth-of-type(2) {
		padding: 0 5px;
		margin-bottom:33px;
	}
	.why-us__arguments-item:nth-of-type(3){
		margin-bottom: 33px;
	}
	.why-us__arguments-item:nth-of-type(4) {
		padding: 0 5px !important;
		margin-bottom: 25px;
	}
	.why-us__arguments-item:nth-of-type(4) .why-us__arguments-text {
		max-width: 100%;
	}
	.why-us__arguments-item:nth-of-type(4) img {
		margin-right: 0;
	}

	.why-us__arguments-item {
		width: 100%;
		margin-bottom: 42px;

	}
	.why-us__arguments-text {
		font-size: 16px;
		padding: 0 5px;
		max-width: 100%;

	}
	.why-us__arguments-item {
		-ms-align-items: center;
		align-items: center;
		text-align: center;
		padding-left: 0;
		padding-right: 0;
	}
	.why-us__partners__info {
		padding: 25px 10px;
	}
	.contact-form__btn:after {
		display: none;
	}
	.why-us__map-holder img {
		max-width: 700px;
		z-index: -1;
	}
	.why-us__map-holder li {
		font-size: 12px;
	}
	.why-us__map-holder {
		margin-bottom: 0;
	}
	.reserve {
		background: url('../img/reserv_bg_mini.jpg') no-repeat center;
		background-size: cover;
		padding-top: 50px;
		padding-bottom: 75px;
	}
	.reserve-heading {
		padding: 0 5px
	}
	.reserve-heading .secondary-heading {
		text-align: center;
		padding: 10px 0;
		margin: 0 -5px 50px;
	}
	.reserve-form >div {
		padding-right: 0;
	}
	.reserve-form__input-block  {
		width: 390px;
		margin-right:auto;
		margin-left: auto;
	}
	.reserve-form__input-block .input-block {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		justify-content:center;
	}
	.reserve-form__input-block .input-item:after {
		display: none;
	}


	.you-result__heading .secondary-heading .accent-underline{
		color: #fff;
	}
	.you-result {
		background:url(../img/result_bg_mini.jpg) no-repeat;
		padding-top: 0px;
		padding-bottom: 100px;
		background-size: cover;
	}

	.you-result__item:first-of-type .you-result__item-text {
		margin-bottom: 35px;
	}
	.you-result__wrap {
		padding-bottom: 0;
	}
	.reviews {
		padding-top: 50px;
	}
	.reviews__heading {
		padding-left: 40px;
		padding-right: 35px;
		margin-top: -5px;

	}
	.reviews__heading .secondary-heading {
		margin-bottom: 15px;
	}

	.review-author__name {
		margin-top: 5px;	
	}
	.slider-navigation {
		display: none !important;
	}
	.slider-arrow {
		top:86%;	
	}
	.slider-arrow-prev {
		left: 30%;
	}
	.slider-arrow-next {
		right: 30%;
	}
	.sale {
		margin-top: 0px;
		padding-top: 155px;
	}
	.slider-wrap {
		padding: 0 15px 105px
	}
	.reviews__heading .secondary-heading:after {
		display: none;
	}
	.review-text {
		text-overflow: ellipsis;
		overflow: hidden !important;
		display: -webkit-box !important;
		-webkit-line-clamp: 10;
		-webkit-box-orient: vertical;
	}

	.sale-btn-holder-wrap {
		padding: 0;
	}
	.review-text:before, .review-text:after {
		display: none;
	}

	.total-heading .secondary-heading {
		margin-bottom: 55px;
	}
	.total-item img {
		margin-right: 0;
	}
	.total-item {
		-ms-align-items: center;
		align-items: center;
		text-align: center;
		margin-bottom: 10px !important;
	}
	.total-item:nth-child(2) {
		margin-bottom: 25px !important;
	}

	.contact-us {
		padding-top: 75px;
		background-position: center -70px;
	}
	.contact-us-heading .secondary-heading:before {
		left:0;
	}
	.contact-us-heading .secondary-heading__secondary {
		padding: 0;
		margin-top: 5px;
	}

	.contact-us-heading .secondary-heading {
		margin-bottom: 28px;

	}
	.policy {
		padding: 5px 0 15px
	}

	.statistic-img {
		display: block;
		margin: 25px 0px;
		max-width: 100%;

	}
	.bonus-form-statistic {
		margin-top: 0px;
	}

	.modal-container {
		padding: 30px 20px;
	}
	.total-heading .secondary-heading:before {
		left: 15%;
	}
}